import { Category } from "../categories";
import { WidgetType } from "../widgetTypes";

export const switchWithAdditionalSwitchQuestionAndSelectRendererField = ({
    pathToSwitchQuestion,
    pathToSecondSwitchQuestion,
    pathToSelectValue,
    category = Category.OTHER,
    label,
    selectLabel,
    secondSwitchLabel = "",
    nestedComponent = false,
    selectOptions,
}) => ({
    [pathToSwitchQuestion]: {
        widgetType: WidgetType.SWITCH_WITH_ADDITIONAL_SWITCH_QUESTION_AND_SELECT,
        category: nestedComponent ? undefined : category,
        defaultRuleProperties: {
            value: {
                type: "switchWithAdditionalSwitchQuestionAndSelect",
                switchValue: {
                    field: pathToSwitchQuestion,
                    value: true,
                },
                selectValue: {
                    field: pathToSelectValue,
                    value: "",
                },
                secondSwitchValue: {
                    field: pathToSecondSwitchQuestion,
                    value: true,
                }
            },
        },
        widgetConfig: {
            label: label,
            secondSwitchLabel,
            values: selectOptions,
            selectLabel
        },
    },
});

