import { Switch, FormControl, TextField, Select, MenuItem, Box, InputLabel } from "@material-ui/core";
import { Trans, useTranslation } from "react-i18next";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import moment from "moment";

export const SwitchWIthYearSelectRenderer = ({ config, rule, idx, handleUpdateRule }) => {
    debugger;
    const { t } = useTranslation();
    return (
        <Box key={idx} display="flex" flexDirection="row" alignItems="center" style={{ gap: "10px" }}>
            <Box display="flex" alignItems="center">
                <Trans i18nKey="global.no" />
                <Switch
                    checked={rule.properties.value.switchValue.value}
                    size="small"
                    onChange={(e) =>
                        handleUpdateRule(idx, {
                            ...rule.properties,
                            value: {
                                ...rule.properties.value,
                                switchValue: {
                                    field: rule.properties.value.switchValue.field,
                                    value: e.target.checked,
                                },
                            },
                        })
                    }
                    color="primary"
                />
                <Trans i18nKey="global.yes" />
            </Box>
            <FormControl size="small">
                <Select
                    disabled={!rule.properties.value.switchValue.value}
                    value={rule.properties.yearOperator}
                    displayEmpty
                    onChange={(e) => {
                        handleUpdateRule(idx, {
                            ...rule.properties,
                            yearOperator: e.target.value,
                        });
                    }}
                >
                    <MenuItem value={""}>{t("global.please-select")}</MenuItem>
                    <MenuItem value={"lt"}>{t("global.before")}</MenuItem>
                    {config.withEqualYear && <MenuItem value={"eq"}>Equal</MenuItem>}
                    <MenuItem value={"gt"}>{t("global.after")}</MenuItem>
                </Select>
            </FormControl>
            <MuiPickersUtilsProvider utils={MomentUtils}>
                <KeyboardDatePicker
                    style={{ width: "120px" }}
                    disabled={!rule.properties.value.switchValue.value}
                    size="small"
                    value={rule.properties.value.yearValue.value ? moment(rule.properties.value.yearValue.value) : null}
                    views={["year"]}
                    list="autocompleteOff"
                    autoComplete="new-password"
                    onChange={(e) => {
                        handleUpdateRule(idx, {
                            ...rule.properties,
                            value: {
                                ...rule.properties.value,
                                yearValue: {
                                    field: rule.properties.value.yearValue.field,
                                    value: e.startOf("year").toISOString(),
                                },
                            },
                        });
                    }}
                    disableFuture
                />
            </MuiPickersUtilsProvider>
            {
                config.withNumberOfEntries && (
                    <div key={idx} style={{ display: "flex", flexDirection: "row", gap: "10px", alignItems: "center" }}>
                        <FormControl size="small">
                             <InputLabel style={{ position: 'absolute', top: '-15px', whiteSpace: 'nowrap'}} shrink>Number of events</InputLabel>
                            <Select
                                style={{
                                    marginTop: 0,
                                }}
                                value={rule.properties.numberOfEntiresOperator}
                                displayEmpty
                                onChange={(e) => {
                                    handleUpdateRule(idx, {
                                        ...rule.properties,
                                        numberOfEntiresOperator: e.target.value,
                                    });
                                }}
                            >
                            { [["", "Please Select"], ["eq", "="], ["gt", ">"], ["lt", "<"]].map(([operator, symbol]) => (
                                <MenuItem key={operator} value={operator}>
                                    {symbol}
                                </MenuItem>)
                            )}
                            </Select>
                        </FormControl>
                        <div style={{ gap: "10px", display: "flex" }}>
                        <TextField
                            size="small"
                            style={{ 'width': '80px' }}
                            InputLabelProps={{
                                shrink: true,

                                style: {
                                    position: "absolute",
                                    top: "-20px",
                                },
                            }}
                            InputProps={{
                                style: {
                                    marginTop: 0,
                                },
                            }}
                            type="number"
                            value={rule.properties.value.numberOfEntriesValue.value}
                            onChange={(e) =>
                                handleUpdateRule(idx, {
                                    ...rule.properties,
                                    value: {
                                        ...rule.properties.value,
                                        numberOfEntriesValue: {
                                            field: rule.properties.value.numberOfEntriesValue.field,
                                            value: e.target.value === "" ? "" : Number(e.target.value),
                                        },
                                    },
                                })
                            }
                        />
                    </div>
                    </div>
                )
                // <div>number of occurrences</div>}
            }
        </Box>
    );
};
