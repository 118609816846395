import { FormGroup } from "@material-ui/core";
import { Field } from "formik";
import EpaTextField from "components/hmo-textfield/EpaTextField";

const IrregularIntake = ({ lang, classes, disabled }) => {
    return (
        <FormGroup style={{ marginTop: 15 }} className={classes["group-special-time"]}>
            <Field
                className={classes["row-special-time"]}
                disabled={disabled}
                name="irregularIntakeWhen"
                label={lang("when")}
                as={EpaTextField}
            />
            <Field
                className={classes["row-unit"]}
                disabled={disabled}
                name="irregularIntakeUnit"
                label={lang("unit")}
                as={EpaTextField}
            />
        </FormGroup>
    );
};

export default IrregularIntake;
