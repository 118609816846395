import { Grid, Typography } from "@material-ui/core";
import { Field } from "formik";
import EpaTextField from "components/hmo-textfield/EpaTextField";
import { validationError } from "scenes/patient/anamnesis/component/config/ValidationSchema";
import SearchableSelectbox from "components/searchable-selectbox/SearchableSelectbox";
import { MedicineForm } from "../model";

const MedicationDescription = ({ form, lang, disabled }) => {
    return (
        <>
            <Grid item xs={12}>
                <Typography color="primary" component="h2" variant="h4">
                    {lang("whichMedicineTake")}
                </Typography>
            </Grid>

            <Grid item xs={12}>
                <Field name="patientId" type="hidden" />
                <Field name="medicine" type="hidden" value="" />
                <Field
                disabled={disabled}
                    walkthrough-element="ingredient"
                    name="ingredient"
                    type="text"
                    label={lang("ingredient")}
                    textFieldProps={{
                        label: "Ingredient",
                    }}
                    as={EpaTextField}
                    {...validationError(form, "ingredient")}
                />
            </Grid>

            <Grid item xs={3}>
                <Field
                disabled={disabled}
                    name="strength"
                    type="text"
                    label={lang("strength")}
                    placeholder={"z.B. 20mg"}
                    walkthrough-element={"medicine-dosage"}
                    as={EpaTextField}
                    {...validationError(form, "strength")}
                />
            </Grid>

            <Grid item xs={4}>
                <Field
                    name="medicineForm"
                    type="text"
                    label={lang("medicineForm")}
                    required
                    variant="outlined"
                    component={SearchableSelectbox}
                    options={MedicineForm(lang)}
                    disabled={disabled}
                    textFieldProps={{
                        label: lang("medicineForm"),
                        "walkthrough-element": "medicine-form",
                    }}
                    {...validationError(form, "medicineForm")}
                />
            </Grid>
        </>
    );
};

export default MedicationDescription;
