import { Category } from "../categories";
import { WidgetType } from "../widgetTypes";

export const switchWithSelectFieldConstructor = ({
    pathToSwitchQuestion,
    pathToSelectValue,
    nestedPathToSelectValue = null,
    category = Category.OTHER,
    label,
    selectLabel,
    nestedComponent = false,
    selectOptions,
}) => ({
    [pathToSwitchQuestion]: {
        widgetType: WidgetType.SWITCH_WITH_SELECT,
        category: nestedComponent ? undefined : category,
        defaultRuleProperties: {
            nestedPath: nestedPathToSelectValue ? nestedPathToSelectValue : undefined,
            value: {
                type: nestedPathToSelectValue ? "switchWithNestedSelect" : "switchWithSelect",
                switchValue: {
                    field: pathToSwitchQuestion,
                    value: true,
                },
                selectValue: {
                    field: pathToSelectValue,
                    value: "",
                },
            },
        },
        widgetConfig: {
            label: label,
            values: selectOptions,
            selectLabel
        },
    },
});

