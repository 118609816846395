import { Typography, IconButton } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import Flex from "components/grid/Flex";
import Accordion from "components/grid/Accordion";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { useTheme } from "@material-ui/core/styles";
import { DATE_FORMAT_TYPE, dateWithNamedTimeZoneFormat } from "../../../../../utils/Date.utils";

const RequestToRemovePending = ({ accessRemovalRequestedDate }) => {
    const { t, i18n } = useTranslation();
    const dateFormat = dateWithNamedTimeZoneFormat(i18n.language, DATE_FORMAT_TYPE.LONG);
    const theme = useTheme();
    return (
        <div
            style={{
                position: "absolute",
                right: "28px",
                top: "2px",
                backgroundColor: "#eee",
                paddingLeft: "6px",
            }}
        >
            <div style={{ color: theme.palette.error.main, fontSize: "13px" }}>
                {t("global.request-to-remove-pending")}
            </div>
            <div style={{ ...theme.typography.caption, color: theme.palette.grey[700] }}>
                {t("global.request-sent") +
                    (accessRemovalRequestedDate ? ' ' + dateFormat(accessRemovalRequestedDate) : ': ' + t('global.date-unknown'))}
            </div>
        </div>
    );
};

const CareProviderWithAccess = ({ careProviderAccess, onDelete }) => {
    const theme = useTheme();
    const { t, i18n } = useTranslation();
    return (
        <Accordion
            disabled
            key={careProviderAccess.id}
            accordionOpen={false}
            containerStyle={{
                boxShadow: "unset",
                border: "1px solid blue",
                borderColor: careProviderAccess?.accessRemovalRequested ? theme.palette.error.main : "blue",
                borderRadius: 10,
            }}
            style={{
                backgroundColor: careProviderAccess?.accessRemovalRequested ? theme.palette.grey[200] : "white",
                borderRadius: 10,
            }}
            titleComponent={
                <Flex
                    item
                    style={{ alignItems: "center", position: "relative" }}
                    container
                    justifyContent={"space-between"}
                >
                    <Flex item container column>
                        <Typography style={{ fontWeight: "bold" }}>{careProviderAccess?.name}</Typography>
                        <Typography style={{ fontSize: 11 }}>{t("global.care-provider")}</Typography>
                    </Flex>
                    {careProviderAccess?.accessRemovalRequested && (
                        <RequestToRemovePending
                            accessRemovalRequestedDate={careProviderAccess?.accessRemovalRequestedDate}
                        />
                    )}
                    <IconButton
                        size="small"
                        onClick={(e) => {
                            e.stopPropagation();
                            onDelete(e);
                        }}
                    >
                        <DeleteOutlineIcon style={{ fontSize: "21px" }} />
                    </IconButton>
                </Flex>
            }
        />
    );
};

export default CareProviderWithAccess;
