import bodybuilder from "bodybuilder";
import { convertByFactors } from "./convertByFactors";
import { isSet as isImmutableSet } from "immutable";
import moment from "moment";

/**
 * @typedef {import('bodybuilder').Bodybuilder} Query
 */

/**
 * @typedef {Object} RuleProperty
 * @property {boolean} disabled - Indicates if the rule is disabled.
 * @property {string} field - The field to be queried.
 * @property {any} value - The value to be used in the query.
 * @property {string} [operator] - The operator to be used in the query.
 */

const ruleHandlers = {
    number: numberHandler,
    string: termHandler,
    boolean: termHandler,
    nested: nestedHandler,
    dateRange: dateRangeHandler,
    range: rangeHandler,
    tags: tagsHandler,
    switchWithSelect: switchWithSelectHandler,
    switchWithAdditionalSwitchQuestionAndSelect: switchWithSelectHandler2,
    switchWithSelectAndTags: switchWithSelectAndTagsHandler,
    switchWithNestedSelect: switchWithNestedSelectHandler,
    switchWithYearSelect: switchWithYearSelectHandler,
    numberOrSelect: numberOrSelectHandler,
};

const shouldProcessRule = ({ properties: { value, disabled, operator } }) => {
    return !disabled && value !== "" && operator !== "";
};

/**
 *
 * @param {Query} query
 * @returns
 */
const mergeRuleWithQuery = (query, rule) => {
    if (rule.type === "group") {
        const groupRules = rule.properties.value;
        const andGroup = rule.properties.conjunction === "and";
        const positiveQueryType = andGroup ? "must" : "should";
        // IDK if this is the right way to handle this, but it works
        // my understanding of bodybuilder query library is limited
        const results = groupRules.map((rule) => mergeRuleWithQuery(bodybuilder(), rule).build().query).filter(Boolean); // For empty nested rules i get null should investigate why
        return query.filter("bool", { [rule.properties.exclude ? "must_not" : positiveQueryType]: results });
    } else {
        const ruleProperties = rule.properties;
        const ruleContext = rule.context || {};
        const value = ruleProperties.value;
        const handler = ruleHandlers[rule.properties.explicitFieldType || value.type || typeof value];
        return handler ? handler(query, ruleProperties, ruleContext) : query;
    }
};

function filterDisabledNodes(tree) {
    return tree.filter(shouldProcessRule).map((node) => {
        if (node.type === "group" && node.properties.value) {
            return {
                ...node,
                properties: {
                    ...node.properties,
                    value: filterDisabledNodes(node.properties.value),
                },
            };
        } else if (node.type === "rule" && node.properties.value && node.properties.value.type === "nested") {
            return {
                ...node,
                properties: {
                    ...node.properties,
                    value: {
                        ...node.properties.value,
                        nestedValues: filterDisabledNodes(node.properties.value.nestedValues),
                    },
                },
            };
        }
        return node;
    });
}

function buildElasticQuery(formTree) {
    const filteredTree = filterDisabledNodes(formTree);
    return filteredTree.reduce(mergeRuleWithQuery, bodybuilder()).build();
}

/**
 * @param {Query} query
 * @param {RuleProperty} ruleProperty
 */
function numberHandler(query, { value, field, operator }) {
    return operator === "eq" || operator === undefined
        ? query.filter("term", field, value)
        : query.filter("range", field, { [operator]: value });
}

/**
 * @param {Query} query
 * @param {RuleProperty} ruleProperty
 */
function termHandler(query, { value, field, emptyAsFalse }) {
    if (value === false && emptyAsFalse) {
        return query.filter("bool", (b) => {
            b.orFilter("bool", "must_not", { exists: { field } });
            return b.orFilter("term", field, value);
        });
    } else {
        return query.filter("term", field, value);
    }
}

/**
 * @param {Query} query
 * @param {RuleProperty} ruleProperty
 */
function tagsHandler(query, { value, field, emptyAsFalse }) {
    const tagsValueKey = value.type === "tags" ? "value" : "tagsValue";
    const unwrappedValue = value[tagsValueKey];
    const isSet = (v) => v instanceof Set || isImmutableSet(v);

    const toArray = (v) => (v instanceof Set ? Array.from(v) : isImmutableSet(v) ? v.toArray() : v);

    const normalizedArrayValue = isSet(unwrappedValue) ? toArray(unwrappedValue) : unwrappedValue;
    return query.filter(
        "bool",
        "must",
        normalizedArrayValue.map(
            (tag) =>
                bodybuilder()
                    .addFilter("term", field + "." + tag, true)
                    .build().query,
        ),
    );
}

/**
 * @param {Query} query
 * @param {RuleProperty} ruleProperty
 */
function switchWithSelectAndTagsHandler(query, { value, field, emptyAsFalse }) {
    const queryWithSwitchWithSelect = switchWithSelectHandler(query, { value });
    const resultQuery = value.switchValue.value
        ? tagsHandler(queryWithSwitchWithSelect, { value, field, emptyAsFalse })
        : queryWithSwitchWithSelect;
    return resultQuery;
}

/**
 * @param {Query} query
 * @param {RuleProperty} ruleProperty
 */
function switchWithSelectHandler(query, { value }) {
    return query.filter("bool", (b) => {
        b.filter("term", value.switchValue.field, value.switchValue.value);
        if (value.switchValue.value && value.selectValue.value) {
            // computed selectValueField is necessary because sometimes we have fields of multiple types like keyword and integer (example anamnesis.COMBINED.haveCOVIDVaccinationTimes)
            const selectValueField =  Number.isInteger(value.selectValue.value) ? value.selectValue.field + ".integer" : value.selectValue.field;
            b.filter("match", selectValueField, value.selectValue.value);
        }
        return b;
    });
}

/**
 * @param {Query} query
 * @param {RuleProperty} ruleProperty
 */
function switchWithSelectHandler2(query, { value }) {
    return query.filter("bool", (b) => {
        b.filter("term", value.switchValue.field, value.switchValue.value);
        if (value.switchValue.value && value.selectValue.value) {
            b.filter("match", value.selectValue.field, value.selectValue.value);
        }
        if (value.switchValue.value) {
            b.filter("term", value.secondSwitchValue.field, value.secondSwitchValue.value);
        }
        return b;
    });
}

/**
 * @param {Query} query
 * @param {RuleProperty} ruleProperty
 */
function switchWithYearSelectHandler(query, { value, yearOperator, numberOfEntiresOperator}) {
    // should get number of events and operator
    // then modify the query if needed
    return query.filter("bool", (b) => {
        b.filter("term", value.switchValue.field, value.switchValue.value);
        if (value.switchValue.value && yearOperator && value.yearValue.value) {
            if(yearOperator === "eq") {
                b.filter("range", value.yearValue.field, {
                    gte: value.yearValue.value,
                    lte: moment(value.yearValue.value).endOf("year").toISOString(),
                })
            } else {
                const yearValue = yearOperator === "gt" ? moment(value.yearValue.value).endOf("year").toISOString() : value.yearValue.value;
                b.filter("range", value.yearValue.field, {
                    [yearOperator]: yearValue,
                })
            }
        }
        if (value.switchValue.value && numberOfEntiresOperator && value.numberOfEntriesValue.value) {
            if(numberOfEntiresOperator === "eq") {
                b.filter("term", value.numberOfEntriesValue.field, value.numberOfEntriesValue.value);
            } else {
                b.filter("range", value.numberOfEntriesValue.field, {
                    [numberOfEntiresOperator]: value.numberOfEntriesValue.value,
                })
            }
        }
        return b;
    });
}

/**
 * @param {Query} query
 * @param {RuleProperty} ruleProperty
 */
function numberOrSelectHandler(query, { value, numOperator }) {
    const { numValue, selectValue, switchValue } = value;
    query.filter("bool", (b) => {
        b.filter("term", switchValue.field, switchValue.value);
        if(numValue.value) {
            if(numOperator === "eq") {
                b.orFilter("term", numValue.field, numValue.value);
            } else {
                b.orFilter("range", numValue.field, { [numOperator]: numValue.value });
            }
            b.orFilter("term", numValue.field, numValue.value);
        }
        if(selectValue.value) {
            b.orFilter("term", selectValue.field, selectValue.value);
        }
        b.filterMinimumShouldMatch(1, true);
        return b;
    });

    return query;
}

/**
 * @param {Query} query
 * @param {RuleProperty} ruleProperty
 */
function switchWithNestedSelectHandler(query, { value, nestedPath }) {
    const a = bodybuilder().addFilter("term", value.switchValue.field, value.switchValue.value).build().query;
    const b = bodybuilder()
        .query("nested", { path: nestedPath }, (f) => {
            return f.query("term", value.selectValue.field, value.selectValue.value);
        })
        .build().query;
    if (value.switchValue.value && value.selectValue.value) {
        return query.filter("bool", "must", [a, b]);
    } else {
        return query.filter("term", value.switchValue.field, value.switchValue.value);
    }
}

/**
 * @param {Query} query
 * @param {RuleProperty} ruleProperty
 */
function dateRangeHandler(query, { value, field }) {
    return value.to === "" && value.from === ""
        ? query
        : query.filter("range", field, {
              lte: value.from ? `now-${value.from}y/d` : undefined,
              gte: value.to ? `now-${value.to}y/d` : undefined,
          });
}

/**
 * @param {Query} query
 * @param {RuleProperty} ruleProperty
 */
function rangeHandler(query, { value, field }) {
    return value.from === "" && value.to === ""
        ? query
        : query.filter("range", field, {
              gte: value.from ? value.from : undefined,
              lte: value.to ? value.to : undefined,
          });
}

const contextualCriteriaHandler = (query, rule) => {
    const { value, field: criteriaName } = rule.properties;
    // TODO: remove magic strings
    // fields should come from config or be part of rule (maybe enriched by ruleContextEnricher)
    if (criteriaName === "medCriteria") {
        if (value === "NON_ACTIVE") {
            query.filter("exists", "medications.deletion_date");
        } else if (value === "ACTIVE") {
            query.notFilter("exists", "medications.deletion_date");
        }
    }
    return query;
};
/**
 * - 'dynamicCriteriaRules' are rules that might depend on context, might use differed fields specified in code, etc.
 * - 'independentRules' are rules that are not dependent on context, they are just simple rules where field matches elasticsearch field
 */
const separateRulesByRulesWithContextAndNot = (ruleProperties) => {
    return ruleProperties.reduce(
        (acc, rule) => {
            const { virtualField } = rule.properties;
            const key = virtualField ? "dynamicCriteriaRules" : "independentRules";
            return {
                ...acc,
                [key]: acc[key].concat(rule),
            };
        },
        { dynamicCriteriaRules: [], independentRules: [] },
    );
};

//#region refactor duplicate code
const factors = {
    kreatinin: {
        mgperdl: 1,
        nmolperlml: 88.42,
        mikromolperll: 88.42,
    },
    hemoglobin: {
        gperdl: 1,
        mmolperl: 0.6206,
    },
    crp: {
        mgperl: 1,
        mgperdl: 0.1,
        nmolperll: 9.52,
        nmolperml: 9520,
    },
    cholesterol: {
        mgperdl: 1,
        mmolperl: 0.0259,
        mmolperldl: 0.259,
    },
};

const enrichNestedRules = (nestedRulesToProcess) => {
    const convertHemoglobin = convertByFactors(factors.hemoglobin);
    const convertKreatinin = convertByFactors(factors.kreatinin);
    const convertCholesterol = convertByFactors(factors.cholesterol);
    const convertCRP = convertByFactors(factors.crp);

    const hemoglobinUnit = nestedRulesToProcess.find((r) => r.properties.field === "labs.hemoglobin_unit")?.properties
        .value;
    const kreatininUnit = nestedRulesToProcess.find((r) => r.properties.field === "labs.kreatinin_unit")?.properties
        .value;
    const totalCholesterolUnit = nestedRulesToProcess.find((r) => r.properties.field === "labs.totalcholesterol_unit")
        ?.properties.value;
    const ldlCholesterolUnit = nestedRulesToProcess.find((r) => r.properties.field === "labs.ldlcholesterol_unit")
        ?.properties.value;
    const hdlCholesterolUnit = nestedRulesToProcess.find((r) => r.properties.field === "labs.hdlcholesterol_unit")
        ?.properties.value;
    const crpUnit = nestedRulesToProcess.find((r) => r.properties.field === "labs.crp_unit")?.properties.value;
    return nestedRulesToProcess.map((r) => {
        if (r.properties.field === "labs.hemoglobin_in_mmolperl") {
            return {
                ...r,
                properties: {
                    ...r.properties,
                    value: convertHemoglobin(r.properties.value, hemoglobinUnit, "mmolperl"),
                },
            };
        }
        if (r.properties.field === "labs.kreatinin_in_mikromolperl") {
            return {
                ...r,
                properties: {
                    ...r.properties,
                    value: convertKreatinin(r.properties.value, kreatininUnit, "mikromolperll"),
                },
            };
        }
        if (r.properties.field === "labs.totalcholesterol_in_mmolperl") {
            return {
                ...r,
                properties: {
                    ...r.properties,
                    value: convertCholesterol(r.properties.value, totalCholesterolUnit, "mmolperl"),
                },
            };
        }
        if (r.properties.field === "labs.ldlcholesterol_in_mmolperl") {
            return {
                ...r,
                properties: {
                    ...r.properties,
                    value: convertCholesterol(r.properties.value, ldlCholesterolUnit, "mmolperl"),
                },
            };
        }
        if (r.properties.field === "labs.hdlcholesterol_in_mmolperl") {
            return {
                ...r,
                properties: {
                    ...r.properties,
                    value: convertCholesterol(r.properties.value, hdlCholesterolUnit, "mmolperl"),
                },
            };
        }
        if (r.properties.field === "labs.crp_in_mgperl") {
            return {
                ...r,
                properties: {
                    ...r.properties,
                    value: convertCRP(r.properties.value, crpUnit, "mgperl"),
                },
            };
        }
        return r;
    });
};

//#endregion

/**
 * @param {Query} query
 * @param {RuleProperty} ruleProperty
 */
function nestedHandler(query, { value, field, exclude, nestedPath }) {
    const nestedFieldPath = nestedPath || field;
    const nestedRulesToProcess = value.nestedValues.filter(shouldProcessRule);
    const enrichedNestedRules = enrichNestedRules(nestedRulesToProcess);
    const { dynamicCriteriaRules, independentRules } = separateRulesByRulesWithContextAndNot(enrichedNestedRules);

    const medCriteriaNeverSubscribedIsPresent = dynamicCriteriaRules.some(
        (rule) => rule.properties.field === "medCriteria" && rule.properties.value === "NEVER_SUBSCRIBED",
    );

    // exclusive or (XOR) so if exclude is true we want to invert the medCriteriaNeverSubscribedIsPresent
    const notQuery = exclude ^ medCriteriaNeverSubscribedIsPresent;
    return independentRules.length === 0 || independentRules.every((v) => v.properties.value === "")
        ? query
        : query[notQuery ? "notQuery" : "query"]("nested", { path: nestedFieldPath }, (f) => {
              return f.query("bool", (b) => {
                  const queryWithProcessedDynamicCriteriaRules = dynamicCriteriaRules.reduce(
                      contextualCriteriaHandler,
                      b,
                  );
                  return independentRules.reduce(mergeRuleWithQuery, queryWithProcessedDynamicCriteriaRules);
              });
          });
}

export default buildElasticQuery;
