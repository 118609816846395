import moment from "moment-timezone";

export const DATE_FORMAT_TYPE = {
    "LONG": "LONG",
    "SHORT": "SHORT",
    "TIME_ONLY": "TIME_ONLY",
}

const DATE_FORMATS = {
    [DATE_FORMAT_TYPE.LONG]: {
        en: "Do [of] MMMM, HH:mm",
        default: "Do MMMM, HH:mm",
    },
    [DATE_FORMAT_TYPE.SHORT]: {
        en: "MMM D, HH:mm",
        default: "D MMM, HH:mm",
    }
};

const parseDateWithNamedTimeZone = (date) => {
    const parts = date.split("[");
    return {
        zonePart: parts[1].replace("]", ""),
        datePart: parts[0],
    }
}

/**
 * Curried function that takes locale and format first then date
 * @returns formatted date string
 */
export const dateWithNamedTimeZoneFormat = (locale, format) => date => {
    if (!date) return "";
    const { datePart, zonePart } = parseDateWithNamedTimeZone(date);

    const formatTemplates = DATE_FORMATS[format] || DATE_FORMATS.LONG;
    const formatTemplateOfLocale = formatTemplates[locale] || formatTemplates.default;

    return moment(datePart)
        .tz(zonePart)
        .locale(locale)
        .local()
        .format(formatTemplateOfLocale);
};
