import React from 'react';
import {Button, Dialog, DialogActions, DialogContentText, DialogTitle} from "@material-ui/core";
import DialogContent from "@material-ui/core/DialogContent";
import {Form, Formik} from "formik";
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';

const CommonDialog = ({
                          handleClose,
                          handleSave,
                          dialogTitle,
                          alternativeSubmitText,
                          open,
                          children,
                          dialogProps = {},
                          validationSchema = Yup.object().shape({}),
                          validate,
                          initialValues = {},
                          dialogContext = null,
                          validateOnChange = true,
                          validateOnBlur = true
                      }) => {
    const { t } = useTranslation();

    return (
        <Dialog open={open} onClose={handleClose} {...dialogProps} aria-labelledby="form-dialog-title">
            <DialogTitle style={{paddingBottom: '2px'}} id="form-dialog-title">{dialogTitle}</DialogTitle>
            <DialogContent>
                {dialogContext && <DialogContentText>
                    {dialogContext}
                </DialogContentText>}
                <Formik
                    validateOnChange={validateOnChange}
                    validateOnBlur={validateOnBlur}
                    initialValues={initialValues}
                    validationSchema={validate ? undefined : validationSchema}
                    validate={validate}
                    onSubmit={(values, {setSubmitting}) => {
                        setTimeout(() => {
                            handleSave(values);
                            setSubmitting(false);
                        }, 400);
                    }}
                >
                    {({isSubmitting, form, ...formProps }) => (
                        <Form>
                            {children({isSubmitting,...formProps})}
                            <DialogActions>
                                <Button variant='outlined' onClick={handleClose}>
                                    {t('global.cancel')}
                                </Button>
                                <Button type="submit" disabled={isSubmitting} variant="contained" color="primary">
                                    {alternativeSubmitText || t('global.submit')}
                                </Button>
                            </DialogActions>
                        </Form>
                    )}
                </Formik>
            </DialogContent>
        </Dialog>
    );
};

export default CommonDialog;
