import { Switch, FormControl, InputLabel, Select, MenuItem, Box } from "@material-ui/core";
import { Trans, useTranslation } from "react-i18next";

const transformValueToValueWithLabel = (item) => {
    if (typeof item === "object") {
        return { value: item.value, label: item.label };
    }
    return { value: item, label: item };
};

const generateMenuItems = (values, t) => {
    return [""]
        .concat(values.filter((v) => v !== ""))
        .map(transformValueToValueWithLabel)
        .map((value, idx) => (
            <MenuItem key={idx} value={value.value}>
                {t(value.value === "" ? "global.please-select" : value.label)?.replace(/&lt;/g, "<").replace(/&gt;/g, ">")}
            </MenuItem>
        ));
};

export const SwitchWithAdditionalSwitchQuestionAndSelectRenderer = ({ config, rule, idx, handleUpdateRule }) => {
    const { t } = useTranslation();
    return (
        <Box key={idx} display="flex" flexDirection="row" alignItems="center" style={{ gap: "10px" }}>
            <Box display="flex" alignItems="center">
                <Trans i18nKey="global.no" />
                <Switch
                    checked={rule.properties.value.switchValue.value}
                    size="small"
                    onChange={(e) =>
                        handleUpdateRule(idx, {
                            ...rule.properties,
                            value: {
                                ...rule.properties.value,
                                switchValue: {
                                    field: rule.properties.value.switchValue.field,
                                    value: e.target.checked,
                                },
                            },
                        })
                    }
                    color="primary"
                />
                <Trans i18nKey="global.yes" />
            </Box>

            <FormControl size="small">
                {config.selectLabel && <InputLabel style={{ position: 'absolute', top: '-15px', whiteSpace: 'nowrap'}} shrink>{t(config.selectLabel)}</InputLabel>}
                <Select
                style={{marginTop: 0}}
                    disabled={!rule.properties.value.switchValue.value}
                    value={rule.properties.value.selectValue.value}
                    displayEmpty
                    onChange={(e) => {
                        handleUpdateRule(idx, {
                            ...rule.properties,
                            value: {
                                ...rule.properties.value,
                                selectValue: { field: rule.properties.value.selectValue.field, value: e.target.value },
                            },
                        });
                    }}
                >
                    {generateMenuItems(config.selectValues || config.values, t)}
                </Select>
            </FormControl>
            <Box display="flex" alignItems="center">

                <div>{t(config.secondSwitchLabel)}:&nbsp;</div>
                <Trans i18nKey="global.no" />
                <Switch
                    checked={rule.properties.value.secondSwitchValue.value}
                    size="small"
                    onChange={(e) => {
                        handleUpdateRule(idx, {
                            ...rule.properties,
                            value: {
                                ...rule.properties.value,
                                secondSwitchValue: {
                                    field: rule.properties.value.secondSwitchValue.field,
                                    value: e.target.checked,
                                },
                            },
                        })
                    }}
                    color="primary"
                />
                <Trans i18nKey="global.yes" />
            </Box>
        </Box>
    );
};
