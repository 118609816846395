import { Category } from "../categories";
import { WidgetType } from "../widgetTypes";

export const switchWithYearSelectConstructor = ({
    pathToSwitchQuestion,
    pathToYearValue,
    pathToNumberOfEntries,
    category = Category.OTHER,
    label,
    withNumberOfEntries = false
}) => ({
    [pathToSwitchQuestion]: {
        widgetType: WidgetType.SWITCH_WITH_YEAR_SELECTOR,
        category: category,
        defaultRuleProperties: {
            value: {
                type: "switchWithYearSelect",
                switchValue: {
                    field: pathToSwitchQuestion,
                    value: true,
                },
                yearValue: {
                    field: pathToYearValue,
                    value: "",
                },
                numberOfEntriesValue: {
                    field: pathToNumberOfEntries,
                    value: "",
                }
            },
            yearOperator: "",
            numberOfEntiresOperator: ""
        },
        widgetConfig: {
            label: label,
            withNumberOfEntries: pathToNumberOfEntries && pathToNumberOfEntries.length > 0
        },
    },
});

