export const GET_PATIENT_LIST = '@managed-care/GET_PATIENT_LIST';
export const GET_PATIENT_LIST_SUCCESS = '@managed-care/GET_PATIENT_LIST_SUCCESS';
export const GET_PATIENT_LIST_FAIL = '@managed-care/GET_PATIENT_LIST_FAIL';

export const GET_PROGRAM_TEMPLATE_LIST = '@managed-care/GET_PROGRAM_TEMPLATE_LIST';
export const GET_PROGRAM_TEMPLATE_LIST_SUCCESS = '@managed-care/GET_PROGRAM_TEMPLATE_LIST_SUCCESS';
export const GET_PROGRAM_TEMPLATE_LIST_FAIL = '@managed-care/GET_PROGRAM_TEMPLATE_LIST_FAIL';

export const GET_CARE_PROVIDER_PROGRAM_TEMPLATE_LIST = '@managed-care/GET_CARE_PROVIDER_PROGRAM_TEMPLATE_LIST';
export const GET_CARE_PROVIDER_PROGRAM_TEMPLATE_LIST_SUCCESS = '@managed-care/GET_CARE_PROVIDER_PROGRAM_TEMPLATE_LIST_SUCCESS';
export const GET_CARE_PROVIDER_PROGRAM_TEMPLATE_LIST_FAIL = '@managed-care/GET_CARE_PROVIDER_PROGRAM_TEMPLATE_LIST_FAIL';

export const SAVE_PROGRAM_TEMPLATE = '@managed-care/SAVE_PROGRAM_TEMPLATE';
export const SAVE_PROGRAM_TEMPLATE_SUCCESS = '@managed-care/SAVE_PROGRAM_TEMPLATE_SUCCESS';
export const SAVE_PROGRAM_TEMPLATE_FAIL = '@managed-care/SAVE_PROGRAM_TEMPLATE_FAIL';

export const UPSERT_CARE_PROGRAM = '@managed-care/UPSERT_CARE_PROGRAM';
export const UPSERT_CARE_PROGRAM_SUCCESS = '@managed-care/UPSERT_CARE_PROGRAM_SUCCESS';
export const UPSERT_CARE_PROGRAM_FAIL = '@managed-care/UPSERT_CARE_PROGRAM_FAIL';

export const GET_PROGRAM_OVERVIEW = '@managed-care/GET_PROGRAM_OVERVIEW';
export const GET_PROGRAM_OVERVIEW_SUCCESS = '@managed-care/GET_PROGRAM_OVERVIEW_SUCCESS';
export const GET_PROGRAM_OVERVIEW_FAIL = '@managed-care/GET_PROGRAM_OVERVIEW_FAIL';

export const GET_PATIENT_OVERVIEW = '@managed-care/GET_PATIENT_OVERVIEW';
export const GET_PATIENT_OVERVIEW_SUCCESS = '@managed-care/GET_PATIENT_OVERVIEW_SUCCESS';
export const GET_PATIENT_OVERVIEW_FAIL = '@managed-care/GET_PATIENT_OVERVIEW_FAIL';

export const UPSERT_CHAT_LOG = '@managed-care/UPSERT_CHAT_LOG';
export const UPSERT_CHAT_LOG_SUCCESS = '@managed-care/UPSERT_CHAT_LOG_SUCCESS';
export const UPSERT_CHAT_LOG_FAIL = '@managed-care/UPSERT_CHAT_LOG_FAIL';

export const DELETE_CHAT_LOG = '@managed-care/DELETE_CHAT_LOG';
export const DELETE_CHAT_LOG_SUCCESS = '@managed-care/DELETE_CHAT_LOG_SUCCESS';
export const DELETE_CHAT_LOG_FAIL = '@managed-care/DELETE_CHAT_LOG_FAIL';

export const NEW_PROGRAM_TEMPLATE = '@managed-care/NEW_PROGRAM_TEMPLATE';
export const NEW_PROGRAM_TEMPLATE_SUCCESS = '@managed-care/NEW_PROGRAM_TEMPLATE_SUCCESS';
export const NEW_PROGRAM_TEMPLATE_FAIL = '@managed-care/NEW_PROGRAM_TEMPLATE_FAIL';

export const NEW_PROGRAM_TEMPLATE_STEP = '@managed-care/NEW_PROGRAM_TEMPLATE_STEP';
export const NEW_PROGRAM_TEMPLATE_STEP_SUCCESS = '@managed-care/NEW_PROGRAM_TEMPLATE_STEP_SUCCESS';
export const NEW_PROGRAM_TEMPLATE_STEP_FAIL = '@managed-care/NEW_PROGRAM_TEMPLATE_STEP_FAIL';

export const DELETE_TEMPLATE = '@managed-care/DELETE_TEMPLATE';
export const DELETE_TEMPLATE_SUCCESS = '@managed-care/DELETE_TEMPLATE_SUCCESS';
export const DELETE_TEMPLATE_FAIL = '@managed-care/DELETE_TEMPLATE_FAIL';

export const NEW_CARE_PROGRAM_STEP = '@managed-care/NEW_CARE_PROGRAM_STEP';
export const NEW_CARE_PROGRAM_STEP_SUCCESS = '@managed-care/NEW_CARE_PROGRAM_STEP_SUCCESS';
export const NEW_CARE_PROGRAM_STEP_FAIL = '@managed-care/NEW_CARE_PROGRAM_STEP_FAIL';

export const SET_SORT_BY = '@managed-care/SET_SORT_BY';
export const SET_SORT_REVERSE = '@managed-care/SET_SORT_REVERSE';

export const SET_LAST_VISIBLE_PATIENT_ID = '@managed-care/SET_LAST_VISIBLE_PATIENT_ID';

export const SET_POSITIVE_SEARCH = '@managed-care/SET_POSITIVE_SEARCH';

export const SET_SELECTED_PROGRAMS = '@managed-care/SET_SELECTED_PROGRAMS';

export const SET_SELECTED_COHORT_ID = '@managed-care/SET_SELECTED_COHORT_ID';
