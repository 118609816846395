import { FormControlLabel, Grid, Radio, RadioGroup, Typography } from "@material-ui/core";
import { Field } from "formik";
import { MedicationPlanType } from "../MedicationPlanApi";
import RegularIntake from "./RegularIntake";
import IrregularIntake from "./IrregularIntake";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((_theme) => ({
    "take-row": {
        maxWidth: "60px",
        margin: "5px",
    },

    "group-special-time": {
        margin: "5px",
        display: "block",
    },

    "row-special-time": {
        maxWidth: "200px",
        marginRight: "5px",
    },

    "row-unit": {
        maxWidth: "100px",
        marginLeft: "5px",
    },
    "take-shrink": {
        '& [class*="MuiInputLabel-root-"]': {
            fontSize: "11px",
            lineHeight: "16px",
        },
        '& [class*="MuiInputLabel-shrink-"]': {
            transform: "translate(0, -1.0px) scale(1)",
            marginTop: "0 !important",
        },
        '& [class*="MuiInputLabel-animated-"]': {
            transition: "all 0s !important",
        },
    },

    button: {
        background: "#245B67",

        "&:hover": {
            background: "#245B67",
        },
    },
}));

const Intake = ({ lang, form, handleMedicineTypeChange, disabled }) => {
    const classes = useStyles();
    return (
        <>
            <Grid item xs={12}>
                <Typography color="primary" component="h2" variant="h4">
                    {lang("whenToTake")}
                </Typography>
            </Grid>

            <Grid item xs={4}>
                <Field
                    name="medicinePlanType"
                    value={form.values.medicinePlanType}
                    onChange={(event, value) => handleMedicineTypeChange(event, value, form)}
                    component={RadioGroup}
                >
                    <FormControlLabel
                        style={{ marginRight: 0 }}
                        disabled={disabled}
                        value={MedicationPlanType.REGULAR}
                        control={<Radio color="primary" />}
                        label={lang("regularIntake")}
                    />

                    <FormControlLabel
                        value={MedicationPlanType.SPECIAL_TIMES}
                        disabled={disabled}
                        control={<Radio color="primary" />}
                        label={lang("irregularIntake")}
                        style={{
                            marginRight: 0,
                            color: form.values.medicinePlanType === MedicationPlanType.REGULAR ? "gray" : "initial",
                        }}
                    />
                </Field>
            </Grid>

            <Grid item xs={8}>
                {form.values.medicinePlanType === MedicationPlanType.REGULAR && (
                    <RegularIntake {...{ lang, classes, disabled }} />
                )}
                {form.values.medicinePlanType === MedicationPlanType.SPECIAL_TIMES && (
                    <IrregularIntake {...{ lang, classes, disabled }} />
                )}
            </Grid>
        </>
    );
};

export default Intake;
