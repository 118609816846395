import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {TextField} from "@material-ui/core";
import {Autocomplete} from "@material-ui/lab";

const SearchableSelectbox = ({field, form, value, options, textFieldProps = {}, autoCompleteProps = {}, disabled}) => {
    const [selectedValue, setSelectedValue] = useState(null);

    const handleOnChangeComponentValue = (e, v) => {
        setSelectedValue(v);
        form.setFieldValue(field.name, v ? v.value : null);
    }

    useEffect(() => {
        if (field != null && field.value != null && options != null && Array.isArray(options)) {
            setSelectedValue(options.find((option) => option.value === field.value));
        }
    }, [field, options]);

    return (
        <Autocomplete
            options={options ?? []}
            disabled={disabled}
            onChange={handleOnChangeComponentValue}
            getOptionLabel={(option) => option.label}
            value={value != null ? options.find((option) => option.value === value) : selectedValue}
            {...autoCompleteProps}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={field.label ?? 'Select an option'}
                    InputLabelProps={{
                        shrink: true,
                    }} {...textFieldProps} />
            )}
        />
    );
};

SearchableSelectbox.propTypes = {
    options: PropTypes.array,
    textFieldProps: PropTypes.object,
    autoCompleteProps: PropTypes.object
};

export default SearchableSelectbox;
