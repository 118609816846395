import { useCallback, useEffect, useReducer, useState } from "react";
import PatientSidebarItem from "../PatientSidebarIem";
import Dropzone from "react-dropzone";
import { getDocumentType } from "scenes/case/edit/component/document/DocumentsSidebar.jsx";
import { isPatient, isManager, isCaseManager } from "domain/User.model";
import { useDispatch, useSelector } from "react-redux";
import UploadDialog from "scenes/case/edit/component/document/UploadDialog.jsx";
import update from "immutability-helper";
import { uploadFileBy } from "scenes/FileHandler.action";
import ListOfFiles from "./ListOfFiles";
import UploadTypeModal from "./UploadTypeModal";
import { useGetMultipleFilesQuery } from "./filesApi";
import HMOApi, { FILE } from "store/api/HMOApi";
import { useGetMultipleDocumentLinksQuery, useUploadDocumentLinkMutation } from "./documentLInks";
import moment from "moment";
import EditDocumentLinkModal from "./EditDocumentLinkModal";
import EditDocumentModal from "./EditDocumentModal";
import { useTranslation } from "react-i18next";
import DragndropZone from "./DragandDropZone";
import FileCopyOutlinedIcon from "@mui/icons-material/FileCopyOutlined";
import { makeStyles, IconButton, CircularProgress, Tooltip } from "@material-ui/core";
import { Box } from "@material-ui/core";
import { CARE_PROGRAM, toFileStorageType } from "domain/EntityAuthorizationType.model";
import {
    UploadFilesAction,
    uploadFilesInitialState,
    uploadFilesReducer,
    UploadFilesDispatchContext,
} from "./uploadFilesState";
import DeleteConfirmationModal from "./DeleteConfirmationModal";
import { CloudDownloadOutlined } from "@material-ui/icons";
import { downloadSelectedDocument } from "scenes/FileHandler.action";
import { DropZoneAction } from "./DropZone.reducer";
import { useParams } from "react-router-dom";
import { downloadPrivacyPolicy, downloadFilledWillingnessDeclaration } from "scenes/registration/Registration.action";

const useStyles = makeStyles((theme) => ({
    collapsedFileList: {
        display: "flex",
        alignItems: "center",
    },
    minimizeLink: {
        color: theme.palette.info.dark,
        textAlign: "center",
        cursor: "pointer",
    },
}));

const documentLinkDataToFileListData = (documentLinkData) => ({
    ...documentLinkData,
    formattedUploadTime: moment(documentLinkData.uploadTime).format("DD MMMM YYYY"),
});

/**
 * @typedef {Object} File
 * @property {string} name name of the file.
 * @property {number} size size of the file in bytes.
 * @property {string} type MIME type of the file.
 * @property {Date} lastModifiedDate last modified date of the file.
 */

const mapLabelToFiles = (filesToShow) => (fileData) => {
    const correspondingFileToShow = filesToShow.find(
        (fileToShow) => fileToShow.entityBusinessId === fileData.entityBusinessId,
    );
    //TODO LM: This is the label under the file name (General, Case, program name, etc...) This should be decided render
    // time based on injected data, not mapped onto the file list. This is severely confusing.
    return {
        label: correspondingFileToShow?.label || "",
        ...fileData,
    };
};

const UploadedFiles = ({
    filesToShow,
    linksToShow,
    fileStorageType,
    entityBusinessId,
    entityId,
    patientId,
    label,
    patientPrograms,
    patientBusinessId,
}) => {
    const { programId } = useParams();
    const [state, dispatchAction] = useReducer(uploadFilesReducer, uploadFilesInitialState);
    const questionId = useSelector((state) => state.DropZoneReducer.anamnesisQuestionId);
    const exposeDropZoneRef = useCallback((ref) => {
        dispatch({ type: DropZoneAction.SetDropZoneRef, payload: ref });
    }, []);
    const [isZippingFiles, setIsZippingFiles] = useState(false);
    const classes = useStyles();
    const { t } = useTranslation();
    const docTypes = useSelector((state) => state.caseReducer.configuration.documentTypes);
    const dispatch = useDispatch();
    const {
        data: filesData,
        isSuccess: filesLoaded,
        isFetching: isFilesDataFetching,
    } = useGetMultipleFilesQuery(filesToShow, { refetchOnMountOrArgChange: true });
    const {
        data: documentLinks,
        isSuccess: documentLinksLoaded,
        isFetching: documentLinksFetching,
    } = useGetMultipleDocumentLinksQuery(linksToShow);

    useEffect(() => {
        dispatch({ type: DropZoneAction.setFilesData, payload: filesData });
    }, [filesData]);

    const [uploadDocumentLinkMutation] = useUploadDocumentLinkMutation();

    const isDataFetching = documentLinksFetching || isFilesDataFetching;

    const user = useSelector((state) => state.mainReducer.user);
    const filteredFilesList = filesData?.filter(
        (item) => isManager(user) || !item?.fileName?.includes("second-opinion-"),
    );
    const totalDocsCount = (filteredFilesList?.length || 0) + (documentLinks?.length || 0);
    const assignableTypes = [
        {
            id: patientId,
            businessId: patientBusinessId,
            patientId,
            fileStorageType: "PATIENT",
            name: "PATIENT",
        },
        ...patientPrograms
            .filter((program) => !program.careProgramData.suspendedParticipation)
            .map((program) => ({
                id: program.careProgramData.id,
                businessId: program.careProgramData.businessId,
                patientId: program.careProgramData.patientId,
                fileStorageType: CARE_PROGRAM,
                name: program.careProgramData.name,
            })),
    ];

    const fileDropHandler = (user, documentTypes, files) => {
        const documentsState = files.map((file) => getDocumentState(file, documentTypes, user));
        dispatchAction({ type: UploadFilesAction.SetFilesToUpload, payload: documentsState });
    };

    const uploadFiles = () => {
        const promises = [];
        // for each is not async
        const filesPreparedToUpload = update(state.filesToUpload, {
            $apply: (files) => files.map((file) => ({ ...file, isUploadInProgress: true })),
        });
        dispatchAction({ type: UploadFilesAction.SetFilesToUpload, payload: filesPreparedToUpload });
        for (const [index, document] of Object.entries(state.filesToUpload)) {
            promises.push(
                new Promise((resolve, reject) => {
                    if (state.isFileUploadDialogOpen) {
                        //TODO LM: Why would i not have documentAssignment?
                        dispatch(
                            uploadFileBy({
                                fileStorageType: document?.documentAssignment?.fileStorageType || fileStorageType,
                                entityBusinessId: document?.documentAssignment?.businessId || entityBusinessId,
                                entityId: document?.documentAssignment?.id || entityId,
                                patientId: document?.documentAssignment?.patientId || patientId,
                                file: document.file,
                                fileName: document.fileName,
                                documentType: document.documentType.enumName,
                                ...(questionId && { questionId }),
                            }),
                        )
                            .then(() => {
                                dispatchAction({
                                    type: UploadFilesAction.SetByIndexFileToUploadAsUploaded,
                                    payload: index,
                                });
                                resolve();
                            })
                            .catch((error) => {
                                dispatchAction({
                                    type: UploadFilesAction.SetByIndexFileToUploadAsError,
                                    payload: {
                                        index,
                                        status: error.error.response.status,
                                    },
                                });
                                reject();
                            });
                    }
                }),
            );
        }
        Promise.allSettled(promises)
            .then((results) => {
                if (!results.map((item) => item.status).includes("rejected")) {
                    dispatchAction({ type: UploadFilesAction.CloseFileUploadDialog });
                }
            })
            .finally(() => {
                dispatch(HMOApi.util.invalidateTags([FILE]));
                dispatch({ type: DropZoneAction.SetAnamnesisQuestionId, payload: null });
            });
    };

    const downloadAll = () => {
        setIsZippingFiles(true);
        dispatch(downloadSelectedDocument(filteredFilesList.map((file) => file.documentId))).finally(() => {
            setIsZippingFiles(false);
        });
    };

    //TODO LM: permanentDocuments should be based on the willingness declaration list of the given care providers
    // of the patient. Right now we dont have that feature yet, so we are winging it based on whether the patient
    // have a second opinion and/or anything else (which means CHIP)
    const secondOpinionProgram = patientPrograms.find(
        (program) =>
            program.careProgramData.name.includes("Zweitmeinung") &&
            (!programId || programId === "" + program.careProgramData.id),
    )?.careProgramData;
    const firstNotSecondOpinionProgram = patientPrograms.find(
        (program) =>
            !program.careProgramData.name.includes("Zweitmeinung") &&
            (!programId || programId === "" + program.careProgramData.id),
    )?.careProgramData;
    const permanentDocuments = [
        ...(secondOpinionProgram
            ? [
                  {
                      label: secondOpinionProgram.name,
                      name: t(`register.privacy-policy`),
                      downloadPermanentDocument: () => dispatch(downloadPrivacyPolicy()),
                  },
              ]
            : []),
        ...(firstNotSecondOpinionProgram
            ? [
                  {
                      label: firstNotSecondOpinionProgram.name,
                      name: t(`register.privacy-policy`),
                      downloadPermanentDocument: () =>
                          dispatch(downloadPrivacyPolicy(firstNotSecondOpinionProgram.careProviderId)),
                  },
                  {
                      label: firstNotSecondOpinionProgram.name,
                      name: t(`register.declaration-of-participation`),
                      downloadPermanentDocument: () =>
                          dispatch(
                              downloadFilledWillingnessDeclaration({
                                  careProviderId: firstNotSecondOpinionProgram.careProviderId,
                                  userDetailsId: patientId,
                              }),
                          ),
                  },
              ]
            : []),
    ];

    return (
        <UploadFilesDispatchContext.Provider value={dispatchAction}>
            <PatientSidebarItem
                fetching={isDataFetching}
                title={label}
                infoHeaderStyle={{ marginBottom: isZippingFiles ? 8 : 2 }}
                titleElements={
                    isCaseManager(user) &&
                    filteredFilesList?.length > 0 && (
                        <>
                            {isZippingFiles && <CircularProgress />}
                            {!isZippingFiles && (
                                <Tooltip title={t("global.download-all-listed-file")}>
                                    <IconButton onClick={downloadAll} style={{ padding: 2 }}>
                                        <CloudDownloadOutlined fontSize={"large"} />
                                    </IconButton>
                                </Tooltip>
                            )}
                        </>
                    )
                }
            >
                <Dropzone
                    onDrop={(files) => fileDropHandler(entityBusinessId, docTypes, files)}
                    ref={exposeDropZoneRef}
                >
                    {({ getRootProps, getInputProps }) => (
                        <section
                            style={{
                                display:
                                    (permanentDocuments?.length === 0 && totalDocsCount === 0) ||
                                    state.isDocListExpanded
                                        ? "inherit"
                                        : "none",
                            }}
                        >
                            <div
                                {...getRootProps()}
                                onClick={() => {
                                    dispatchAction({ type: UploadFilesAction.ToggleFileUploadTypeDialog });
                                }}
                            >
                                <input data-testid="doc-upload" {...getInputProps()} />
                                <DragndropZone />
                            </div>
                        </section>
                    )}
                </Dropzone>
                {(permanentDocuments?.length === 0 && totalDocsCount === 0) || state.isDocListExpanded ? (
                    <>
                        {filesLoaded && documentLinksLoaded && (
                            <ListOfFiles
                                permanentDocuments={permanentDocuments}
                                files={filteredFilesList
                                    .map(mapLabelToFiles(filesToShow))
                                    .sort(
                                        (a, b) => new Date(b.uploadTime).getTime() - new Date(a.uploadTime).getTime(),
                                    )}
                                links={documentLinks
                                    .map(mapLabelToFiles(linksToShow))
                                    .map(documentLinkDataToFileListData)
                                    .sort(
                                        (a, b) => new Date(b.uploadTime).getTime() - new Date(a.uploadTime).getTime(),
                                    )}
                                locallyReadDocumentIds={state.locallyReadDocumentIds}
                            />
                        )}

                        <div
                            className={classes.minimizeLink}
                            onClick={() => {
                                dispatchAction({ type: UploadFilesAction.ToggleDocumentListExpansion });
                            }}
                        >
                            {t("global.minimize-list-of-files")}
                        </div>
                    </>
                ) : (
                    <div
                        onClick={() => {
                            dispatchAction({ type: UploadFilesAction.ToggleDocumentListExpansion });
                        }}
                        className={classes.collapsedFileList}
                        style={{ cursor: "pointer", padding: "6px 0" }}
                    >
                        <Box display="flex" alignItems="center" flexDirection="row">
                            <FileCopyOutlinedIcon color="primary" style={{ fontSize: "24px" }} />
                            <div
                            data-testid="expand-file-list"
                                style={{
                                    marginLeft: "6px",
                                    lineHeight: "1em",
                                    fontWeight: "bold",
                                    fontSize: "17px",
                                }}
                            >
                                {totalDocsCount !== 0 && (
                                    <>
                                        {totalDocsCount}{" "}
                                        {totalDocsCount === 1 ? t("documentUpload.file") : t("documentUpload.files")}
                                    </>
                                )}
                                {permanentDocuments?.length !== 0 &&
                                    totalDocsCount === 0 &&
                                    t("documentUpload.open-file-list")}
                            </div>
                        </Box>
                    </div>
                )}
            </PatientSidebarItem>
            <UploadTypeModal
                isOpen={state.isFileUploadTypeDialogOpen}
                onFilesSelect={(files) => fileDropHandler(entityBusinessId, docTypes, files)}
                assignmentTypes={assignableTypes}
                assignmentType={assignableTypes.find((x) => x.businessId === entityBusinessId) || assignableTypes[0]}
                onLinkUpload={(documentLinkDTO) => {
                    //TODO LM: This might as well happen inside the component, its so hardwired.
                    uploadDocumentLinkMutation({
                        id: documentLinkDTO.assignment.businessId,
                        storageTag: documentLinkDTO.assignment.fileStorageType,
                        documentLinkDTO,
                        patientId
                    });
                }}
            />
            <UploadDialog
                open={state.isFileUploadDialogOpen || false}
                closeUploadDialog={() => {
                    dispatchAction({ type: UploadFilesAction.CloseFileUploadDialog });
                }}
                uploadFiles={uploadFiles}
                documentTypes={docTypes}
                filesToUpload={state.filesToUpload}
                handleFilesToUploadChange={(files) => {
                    dispatchAction({ type: UploadFilesAction.SetFilesToUpload, payload: files });
                }}
                assignmentTypes={assignableTypes}
                assignmentType={assignableTypes.find((x) => x.businessId === entityBusinessId) || assignableTypes[0]}
            />
            <EditDocumentLinkModal
                editedDocumentLink={state.documentLinkEditDialog.link}
                isOpen={state.documentLinkEditDialog.isOpen}
            />
            <EditDocumentModal
                isOpen={state.documentEditDialog.isOpen}
                documentTypes={docTypes}
                editedDocument={state.documentEditDialog.document}
                assignmentType={assignableTypes.find((x) => x.businessId === entityBusinessId) || assignableTypes[0]}
            />
            <DeleteConfirmationModal
                documentType={state.deleteConfirmationDialog.documentType}
                documentName={state.deleteConfirmationDialog.documentName}
                id={state.deleteConfirmationDialog.documentId}
                isOpen={state.deleteConfirmationDialog.isOpen}
            />
        </UploadFilesDispatchContext.Provider>
    );
};

/**
 * @param {File} file
 */
export function getDocumentState(file, documentTypes, user) {
    let originalFileNameParts = file?.name.split(".") || [];
    const fileExtension = originalFileNameParts.length > 1 ? originalFileNameParts.pop() : "";
    const fileNameWithoutExtension = originalFileNameParts.join("");
    const defaultToGeneral = isPatient(user);
    const documentType = getDocumentType(fileNameWithoutExtension, documentTypes);
    return {
        file,
        fileName: fileNameWithoutExtension + "." + fileExtension,
        fileExtension,
        fileNameWithoutExtension,
        documentType: documentType
            ? documentType
            : defaultToGeneral
              ? documentTypes.find((type) => type.enumName === "GENERAL")
              : undefined,
    };
}

export default UploadedFiles;
