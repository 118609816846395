import { geneValues, sampleLocations } from "../../patient/anamnesis/component/section/MutationModalForm";
import { ProblemSeverityEnum, DiscomfortLevelEnum } from "../../patient/anamnesis/enums";
import { MentalDiscomfortLevelEnum } from "../../patient/anamnesis/enums/MentalDiscomfortLevel.copy";
import { Category } from "./categories";
import { createLabValueConfig } from "./createNestedConfig";
import {
    dropdownFieldConfigConstructor,
    rangeFieldConfigConstructor,
    switchWithAutoCompleteFieldConfigConstructor,
    switchFieldConstructor,
    switchWithSelectFieldConstructor,
    numberFieldConstructor,
    autocompleteFieldConstructor,
    numberOrSelectFieldConstructor,
    percentRangeFieldConstructor,
    ageRangeFieldConstructor,
} from "./fieldContructors";
import { nestedFieldConstructor } from "./fieldContructors/nestedField";
import { switchWithAdditionalSwitchQuestionAndSelectRendererField } from "./fieldContructors/switchWithAdditionalSwitchQuestionAndSelectRendererFeild";
import { switchWithSelectAndTagsFieldConstructor } from "./fieldContructors/switchWithSelectAndTags";
import { switchWithYearSelectConstructor } from "./fieldContructors/switchWIthYearSelect";

const dropDownValues = {
    treatmentStatus: [
        { value: "TREATED", label: "global.treated" },
        { value: "UNTREATED", label: "global.untreated" },
    ],
    problemSeverity: Object.values(ProblemSeverityEnum).map((value) => ({
        value,
        label: `anamnesis.quality.${value}`,
    })),
    mentalProblemsSeverity: Object.values(MentalDiscomfortLevelEnum).map((value) => ({
        value,
        label: `anamnesis.quality.${value}`,
    })),
    discomfortLevel: Object.values(DiscomfortLevelEnum).map((value) => ({
        value,
        label: `anamnesis.quality.${value}`,
    })),
    bloodCountChange: [
        "LEUKOCYTE_INCREASE",
        "LEUKOCYTE_REDUCTION",
        "ANAMIE",
        "INCREASE_IN_HEMATOCRIT",
        "INCREASE_IN_PLATELETS",
        "REDUCTION_IN_PLATELETS",
    ].map((value) => ({
        value,
        label: `anamnesis.hematology.${value}`,
    })),
    CHRSScoreType: ["low", "intermediate", "high"].map((value) => ({
        value,
        label: value ? `my-risk.score-label.${value}` : "",
    })),
    chipSampleLocations: sampleLocations.map((value) => ({
        value,
        label: `anamnesis.chip.source.${value}`,
    })),
    cardiomypathyDiagnose: [
        { value: "DILATIVE", label: "anamnesis.cardio.haveCardiomypathyDiagnoseValue.DILATIVE" },
        { value: "ISCHAEMIC", label: "anamnesis.cardio.haveCardiomypathyDiagnoseValue.ISCHAEMIC" },
        { value: "OTHER", label: "anamnesis.cardio.haveCardiomypathyDiagnoseValue.OTHER" },
        { value: "UNCLEAR", label: "anamnesis.cardio.haveCardiomypathyDiagnoseValue.UNCLEAR" },
    ],
    atherosclerosisTreatment: [
        {
            value: "MEDICINAL",
            label: "anamnesis.cardio.MEDICINAL",
        },
        {
            value: "ZNSURGERY",
            label: "anamnesis.cardio.ZNSURGERY",
        },
        {
            value: "ZNSTENT",
            label: "anamnesis.cardio.ZNSTENT",
        },
    ],
    atherosclerosisLegArteriesStage: [
        {
            label: "anamnesis.cardio.STAGE_I",
            value: "STAGE_I",
        },
        {
            label: "anamnesis.cardio.STAGE_IIa",
            value: "STAGE_IIa",
        },
        {
            label: "anamnesis.cardio.STAGE_IIb",
            value: "STAGE_IIb",
        },
        {
            label: "anamnesis.cardio.STAGE_III",
            value: "STAGE_III",
        },
        {
            label: "anamnesis.cardio.STAGE_IV",
            value: "STAGE_IV",
        },
        {
            label: "anamnesis.cardio.ZNOP",
            value: "ZNOP",
        },
        {
            label: "anamnesis.cardio.ZNSTENT",
            value: "ZNSTENT",
        },
    ],
    restrictions: [
        { value: "NORMAL", label: "Normal" },
        { value: "SLIGHTLY_IMPAIRED", label: "slightly restricted" },
        { value: "MODERATELY_IMPAIRED", label: "moderately restricted" },
        { value: "HIGHLY_RESTRICTED", label: "highly restricted" },
    ],
    depressionTreatment: [
        { value: "TREATED_MEDICATION", label: "anamnesis.comorbidity.TREATED_MEDICATION" },
        { value: "TREATED_THERAPY", label: "anamnesis.comorbidity.TREATED_THERAPY" },
        { value: "UNTREATED", label: "anamnesis.comorbidity.UNTREATED" },
    ],
};

export const fields = {
    ...dropdownFieldConfigConstructor({
        category: Category.GENERAL,
        pathToValue: "bio_sex",
        dropdownValues: [
            { value: "MAN", label: "global.male-bio-sex" },
            {
                value: "WOMAN",
                label: "global.female-bio-sex",
            },
        ],
        label: "anamnesis.general.genderLabel",
    }),
    ...dropdownFieldConfigConstructor({
        category: Category.RISK_SCORE,
        pathToValue: "risk.hematology_score_type",
        dropdownValues: dropDownValues.CHRSScoreType,
        label: "Clonal Hematopoiesis Risk Score (CHRS)",
    }),
    ...rangeFieldConfigConstructor({
        category: Category.RISK_SCORE,
        pathToValue: "risk.full_risk_percent",
        label: "my-risk.cardiovascular-risk-percent",
        fieldWidth: 56,
    }),
    ...rangeFieldConfigConstructor({
        category: Category.GENERAL,
        pathToValue: "anamnesis.GENERAL.height",
        label: "anamnesis.general.heightLabel",
        fieldWidth: 56,
    }),
    ...rangeFieldConfigConstructor({
        category: Category.GENERAL,
        pathToValue: "anamnesis.GENERAL.weight",
        label: "anamnesis.general.weightLabel",
        fieldWidth: 56,
    }),
    ...switchFieldConstructor({
        category: Category.CARDIO_RISK_FACTORS,
        pathToValue: "anamnesis.CARDIO_DISEASE.hasHealthyDiet",
        label: "anamnesis.cardiovascular.hasHealthyDiet",
    }),
    ...switchFieldConstructor({
        category: Category.CARDIO_RISK_FACTORS,
        pathToValue: "anamnesis.CARDIO_DISEASE.hasTimePressureAndStress",
        label: "anamnesis.cardiovascular.hasTimePressureAndStress",
    }),
    ...switchWithSelectFieldConstructor({
        pathToSwitchQuestion: "anamnesis.CCS.hasCCS",
        pathToSelectValue: "anamnesis.CCS.ccsValue",
        label: "anamnesis.clinical.CCSValueLabelInQuery",
        selectOptions: ["CCS2", "CCS3", "CCS4"],
        category: Category.RISK_SCORE,
    }),
    ...numberFieldConstructor({
        category: Category.CHIP,
        pathToValue: "anamnesis.RISK_FACTOR.chipMutationData.values_count",
        label: "anamnesis.chip.mutationCount",
    }),
    ...switchWithAutoCompleteFieldConfigConstructor({
        category: Category.ARTHROSE,
        pathToSelectValues: "anamnesis.ARTHROSE.haveArthroseData.values",
        pathToSwitchQuestion: "anamnesis.ARTHROSE.haveArthrose",
        autocompleteLabel: "anamnesis.arthrose.which-joint",
        fieldLabel: "anamnesis.arthrose.title",
    }),
    ...ageRangeFieldConstructor({
        pathToValue: "age",
        label: "global.age",
        category: Category.GENERAL,
        defaultFromValue: 18,
        defaultToValue: 40,
        fieldWidth: 56,
    }),
    ...switchFieldConstructor({
        category: Category.CARDIO_RISK_FACTORS,
        pathToValue: "anamnesis.CARDIOVASCULAR.smokeStatus",
        label: "anamnesis.cardiovascular.smokeStatus",
    }),
    ...switchFieldConstructor({
        category: Category.CARDIO_RISK_FACTORS,
        pathToValue: "anamnesis.CARDIOVASCULAR.oldSmokeStatus",
        label: "anamnesis.cardiovascular.oldSmokeStatus",
    }),
    ...switchFieldConstructor({
        category: Category.CARDIO_RISK_FACTORS,
        pathToValue: "anamnesis.CARDIOVASCULAR.alcoholDrinkingStatus",
        label: "anamnesis.cardiovascular.alcoholDrinkingStatus",
    }),
    ...switchFieldConstructor({
        category: Category.CARDIO_RISK_FACTORS,
        pathToValue: "anamnesis.CARDIOVASCULAR.familyHeartAttackOrStrokeStatus",
        label: "anamnesis.cardiovascular.familyHeartAttackOrStrokeStatus",
    }),
    ...switchWithYearSelectConstructor({
        category: Category.CARDIOVASCULAR_DISEASES,
        pathToSwitchQuestion: "anamnesis.CARDIO_DISEASE.haveTransientIschemicAttack",
        pathToYearValue: "anamnesis.CARDIO_DISEASE.haveTransientIschemicAttackData.values.date",
        withEqualYear: true,
        label: "anamnesis.cardio.haveTransientIschemicAttack",
    }),
    ...switchWithSelectFieldConstructor({
        pathToSwitchQuestion: "anamnesis.CARDIO_DISEASE.haveCongenitalHeartDefect",
        pathToSelectValue: "anamnesis.CARDIO_DISEASE.haveCongenitalHeartDefectType",
        category: Category.CARDIOVASCULAR_DISEASES,
        label: "anamnesis.cardio.haveCongenitalHeartDefect",
        selectOptions: [
            {
                label: "VSD - Ventrikelseptumdefekt (Kammerseptumdefekt)",
                value: "VSD",
            },
            {
                label: "ASD - Atriumseptumdefekt (Vorhofseptumdefekt)",
                value: "ASD",
            },
            {
                label: "PDA - Persistierender Ductus arteriosus",
                value: "PDA",
            },
            {
                label: "PaV - Pulmonalklappenstenose",
                value: "PaV",
            },
            {
                label: "ISTA - Aortenisthmusstenose",
                value: "ISTA",
            },
            {
                label: "AoV - Aortenklappenstenose",
                value: "AoV",
            },
            {
                label: "TOF - Fallot-Tetralogie",
                value: "TOF",
            },
            {
                label: "AVSD - Atrioventrikulärer Septumdefekt",
                value: "AVSD",
            },
            {
                label: "TGA - Transposition der großen Gefäße",
                value: "TGA",
            },
            {
                label: "HLHS - Hypoplastisches Linksherzsyndrom",
                value: "HLHS",
            },
            {
                label: "PA+VSD - Pulmonalatresie mit Ventrikelseptumdefekt",
                value: "PAVSD",
            },
            {
                label: "PA - Pulmonalatresie ohne Ventrikelseptumdefekt",
                value: "PA",
            },
            {
                label: "TrA - Trikuspidalatresie",
                value: "TrA",
            },
            {
                label: "DIV - Double inlet ventricle (singulärer Ventrikel)",
                value: "DIV",
            },
            {
                label: "DORV - Double outlet right ventricle",
                value: "DORV",
            },
            {
                label: "ccTGA - Angebore",
                value: "ccTGA",
            },
            {
                label: "TAC - Truncus arteriosus communis",
                value: "TAC",
            },
            {
                label: "HOCM - Hypertrophe obstruktive Kardiomyopathie",
                value: "HOCM",
            },
            {
                label: "Suprav. AS - Supravalvuläre Aortenstenose (Williams-Beuren-Syndrom)",
                value: "SupravAS",
            },
        ],
    }),
    ...switchWithSelectAndTagsFieldConstructor({
        category: Category.RISK_SCORE,
        pathToValue: "anamnesis.NYHA",
        pathToSwitchQuestion: "anamnesis.NYHA.hasNYHA",
        pathToSelectValue: "anamnesis.NYHA.nyhaValue",
        label: "anamnesis.clinical.NYHAValueLabelInQuery",
        selectOptions: ["NYHA2", "NYHA3", "NYHA4"],
        tagOptions: [
            { value: "rattlingNoises", label: "anamnesis.clinical.rattlingNoisesButton" },
            { value: "neckVeinCongestion", label: "anamnesis.clinical.neckVeinCongestionButton" },
            { value: "periphereOdeme", label: "anamnesis.clinical.periphereOdemeButton" },
            { value: "ruhedyspnose", label: "anamnesis.clinical.ruhedyspnoseButton" },
        ],
    }),
    ...switchWithSelectFieldConstructor({
        category: Category.CARDIOVASCULAR_DISEASES,
        pathToSwitchQuestion: "anamnesis.CARDIO_DISEASE.haveSufferFromHeartFailure",
        label: "anamnesis.cardio.haveSufferFromHeartFailure",
        selectLabel: "anamnesis.cardio.haveSufferFromHeartFailureDecompensation.statementLabel",
        pathToSelectValue: "anamnesis.CARDIO_DISEASE.haveSufferFromHeartFailureDecompensation",
        selectOptions: [
            {
                label: "< 3",
                value: "<3",
            },
            {
                label: "3-5",
                value: "3-5",
            },
            {
                label: "> 5",
                value: ">5",
            },
        ],
    }),
    ...numberOrSelectFieldConstructor({
        category: Category.CARDIOVASCULAR_DISEASES,
        pathToValue: "anamnesis.CARDIO_DISEASE.haveCardiacEjectionFraction",
        pathToNumValue: "anamnesis.CARDIO_DISEASE.haveCardiacEjectionFractionPercent",
        pathToSelectValue: "anamnesis.CARDIO_DISEASE.haveCardiacEjectionFractionDescription",
        withComparisonOperators: false,
        label: "anamnesis.cardio.haveCardiacEjectionFraction",
        selectOptions: dropDownValues.restrictions,
    }),
    ...nestedFieldConstructor({
        category: Category.CHIP,
        label: "anamnesis.chip.mutationData",
        nestedPath: "anamnesis.RISK_FACTOR.chipMutationData.values",
        allowOrCondition: true,
        canExclude: true,
        additionalFieldProperties: {
            "anamnesis.RISK_FACTOR.chipMutationData.values.vaf": {
                operator: "eq",
            },
        },
        nestedConfigs: [
            dropdownFieldConfigConstructor({
                pathToValue: "anamnesis.RISK_FACTOR.chipMutationData.values.gene",
                label: "anamnesis.chip.table.gene",
                nestedComponent: true,
                dropdownValues: geneValues,
                fieldWidth: 150,
            }),
            numberFieldConstructor({
                pathToValue: "anamnesis.RISK_FACTOR.chipMutationData.values.exon",
                label: "Exon",
                nestedComponent: true,
                fieldWidth: 50,
                withComparisonOperators: false,
            }),
            autocompleteFieldConstructor({
                pathToValue: "anamnesis.RISK_FACTOR.chipMutationData.values.dna",
                label: "anamnesis.chip.table.dna",
                fieldWidth: 150,
                nestedComponent: true,
            }),
            autocompleteFieldConstructor({
                pathToValue: "anamnesis.RISK_FACTOR.chipMutationData.values.protein",
                label: "anamnesis.chip.table.protein",
                fieldWidth: 150,
                nestedComponent: true,
            }),
            numberFieldConstructor({
                pathToValue: "anamnesis.RISK_FACTOR.chipMutationData.values.vaf",
                label: "anamnesis.chip.table.vaf",
                fieldWidth: 80,
                nestedComponent: true,
            }),
            dropdownFieldConfigConstructor({
                pathToValue: "anamnesis.RISK_FACTOR.chipMutationData.values.source",
                nestedComponent: true,
                dropdownValues: dropDownValues.chipSampleLocations,
                label: "Source",
                fieldWidth: 180,
            }),
        ],
    }),
    ...dropdownFieldConfigConstructor({
        pathToValue: "anamnesis.RISK_FACTOR.chipMutationData.values.gene",
        label: "anamnesis.chip.table.gene",
        nestedComponent: true,
        dropdownValues: geneValues,
        fieldWidth: 150,
    }),
    ...numberFieldConstructor({
        pathToValue: "anamnesis.RISK_FACTOR.chipMutationData.values.vaf",
        label: "anamnesis.chip.table.vaf",
        fieldWidth: 80,
        nestedComponent: true,
    }),
    ...numberFieldConstructor({
        pathToValue: "anamnesis.RISK_FACTOR.chipMutationData.values.exon",
        label: "Exon",
        nestedComponent: true,
        fieldWidth: 50,
        withComparisonOperators: false,
    }),
    ...autocompleteFieldConstructor({
        pathToValue: "anamnesis.RISK_FACTOR.chipMutationData.values.dna",
        label: "anamnesis.chip.table.dna",
        fieldWidth: 150,
        nestedComponent: true,
    }),
    ...autocompleteFieldConstructor({
        pathToValue: "anamnesis.RISK_FACTOR.chipMutationData.values.protein",
        label: "anamnesis.chip.table.protein",
        fieldWidth: 150,
        nestedComponent: true,
    }),
    ...dropdownFieldConfigConstructor({
        pathToValue: "anamnesis.RISK_FACTOR.chipMutationData.values.source",
        nestedComponent: true,
        dropdownValues: dropDownValues.chipSampleLocations,
        label: "Source",
        fieldWidth: 180,
    }),
    ...switchFieldConstructor({
        category: Category.OTHER,
        pathToValue: "anamnesis.COMBINED.haveAllergies",
        label: "anamnesis.allergies.title",
    }),
    ...switchFieldConstructor({
        category: Category.CARDIO_RISK_FACTORS,
        pathToValue: "anamnesis.CARDIOVASCULAR.hasCardiovascularDisease",
        label: "anamnesis.cardiovascular.hasCardiovascularDisease",
    }),
    ...switchWithSelectFieldConstructor({
        category: Category.CARDIO_RISK_FACTORS,
        pathToSwitchQuestion: "anamnesis.CARDIOVASCULAR.hasSufferingFromDiabetes",
        pathToSelectValue: "anamnesis.CARDIOVASCULAR.diabetesState",
        selectOptions: dropDownValues.treatmentStatus,
        label: "Diabetes mellitus",
    }),
    ...switchWithSelectFieldConstructor({
        category: Category.COMORBIDITIES,
        pathToSwitchQuestion: "anamnesis.COMBINED.haveCOVID",
        pathToSelectValue: "anamnesis.COMBINED.haveCOVIDTimesInfected",
        label: "anamnesis.comorbidity.haveCOVID",
        selectLabel: "anamnesis.comorbidity.haveCOVIDTimesInfectedLabel",
        selectOptions: [1, 2, 3, 4, 5]
        .map(v => ({label: v, value: v}))
        .concat({label: "> 5", value: "GT5"}),
    }),
    ...switchWithSelectFieldConstructor({
        category: Category.COMORBIDITIES,
        pathToSwitchQuestion: "anamnesis.COMBINED.haveCOVIDVaccination",
        pathToSelectValue: "anamnesis.COMBINED.haveCOVIDVaccinationTimes",
        label: "anamnesis.comorbidity.haveCOVIDVaccination",
        selectLabel: "anamnesis.comorbidity.haveCOVIDVaccinationTimesQuestion",
        selectOptions: [1, 2, 3, 4, 5]
        .map(v => ({label: v, value: v}))
        .concat({label: "> 5", value: "GT5"}),
    }),
    ...switchFieldConstructor({
        category: Category.COMORBIDITIES,
        pathToValue: "anamnesis.COMBINED.haveAsthma",
        label: "anamnesis.comorbidity.asthma",
    }),
    ...switchFieldConstructor({
        category: Category.COMORBIDITIES,
        pathToValue: "anamnesis.COMBINED.haveGastrointestinalBleeding",
        label: "anamnesis.comorbidity.haveGastrointestinalBleeding",
    }),
    ...switchWithSelectFieldConstructor({
        pathToSwitchQuestion: "anamnesis.COMBINED.haveMigraine",
        pathToSelectValue: "anamnesis.COMBINED.haveMigraineTimesPerMonth",
        label: "anamnesis.comorbidity.haveMigraine",
        category: Category.COMORBIDITIES,
        selectOptions: [
            {
                label: "< 3",
                value: "LT3",
            },
            {
                label: "3-5",
                value: "3-5",
            },
            {
                label: "> 5",
                value: "GT5",
            },
        ],
    }),
    ...switchWithSelectFieldConstructor({
        category: Category.COMORBIDITIES,
        pathToSwitchQuestion: "anamnesis.COMBINED.haveDepression",
        pathToSelectValue: "anamnesis.COMBINED.haveDepressionType",
        label: "anamnesis.comorbidity.haveDepression",
        selectOptions: dropDownValues.depressionTreatment,
    }),
    ...switchFieldConstructor({
        category: Category.COMORBIDITIES,
        pathToValue: "anamnesis.COMBINED.haveDepression",
        label: "anamnesis.comorbidity.dementia",
    }),
    ...switchWithYearSelectConstructor({
        category: Category.COMORBIDITIES,
        label: "anamnesis.comorbidity.haveThromboses",
        pathToYearValue: "anamnesis.COMBINED.haveThrombosesData.values.date",
        pathToSwitchQuestion: "anamnesis.COMBINED.haveThromboses",
    }),
    ...switchFieldConstructor({
        category: Category.COMORBIDITIES,
        pathToValue: "anamnesis.COMBINED.haveCOPD",
        label: "anamnesis.comorbidity.haveCOPD",
    }),
    ...switchFieldConstructor({
        category: Category.COMORBIDITIES,
        pathToValue: "anamnesis.COMBINED.haveOtherBleeding",
        label: "anamnesis.comorbidity.haveOtherBleeding",
    }),
    ...switchFieldConstructor({
        category: Category.COMORBIDITIES,
        pathToValue: "anamnesis.COMBINED.haveRestlessLegsSyndrome",
        label: "anamnesis.comorbidity.haveRestlessLegsSyndrome",
    }),
    ...switchFieldConstructor({
        category: Category.COMORBIDITIES,
        pathToValue: "anamnesis.COMBINED.havePulmonaryHypertension",
        label: "anamnesis.comorbidity.havePulmonaryHypertension",
    }),
    ...switchFieldConstructor({
        category: Category.HEMATOLOGY,
        pathToValue: "anamnesis.HEMATOLOGY.haveHematoFamilialSyndromOrGenetic",
        label: "anamnesis.hematology.haveHematoFamilialSyndromOrGenetic",
    }),
    ...switchWithAutoCompleteFieldConfigConstructor({
        category: Category.HEMATOLOGY,
        pathToSelectValues: "anamnesis.HEMATOLOGY.haveActiveHematoDiseaseData.values",
        pathToSwitchQuestion: "anamnesis.HEMATOLOGY.haveActiveHematoDisease",
        autocompleteLabel: "anamnesis.hematology.what-kind",
        fieldLabel: "anamnesis.hematology.haveActiveHematoDisease",
    }),
    ...switchWithAutoCompleteFieldConfigConstructor({
        category: Category.HEMATOLOGY,
        pathToSelectValues: "anamnesis.HEMATOLOGY.haveFormerHematoDiseaseData.values",
        pathToSwitchQuestion: "anamnesis.HEMATOLOGY.haveFormerHematoDisease",
        autocompleteLabel: "anamnesis.hematology.what-kind",
        fieldLabel: "anamnesis.hematology.haveFormerHematoDisease",
    }),
    ...switchWithSelectFieldConstructor({
        category: Category.HEMATOLOGY,
        pathToSwitchQuestion: "anamnesis.HEMATOLOGY.havePreviousChangeInBloodCount",
        pathToSelectValue: "anamnesis.HEMATOLOGY.havePreviousChangeInBloodCountData.values.value",
        nestedPathToSelectValue: "anamnesis.HEMATOLOGY.havePreviousChangeInBloodCountData.values",
        label: "anamnesis.hematology.havePreviousChangeInBloodCount",
        selectOptions: dropDownValues.bloodCountChange,
    }),
    ...switchWithSelectFieldConstructor({
        category: Category.HEMATOLOGY,
        pathToSwitchQuestion: "anamnesis.HEMATOLOGY.haveCurrentChangeInBloodCount",
        pathToSelectValue: "anamnesis.HEMATOLOGY.haveCurrentChangeInBloodCountData.values.value",
        selectOptions: dropDownValues.bloodCountChange,
        label: "anamnesis.hematology.haveCurrentChangeInBloodCount",
        nestedPathToSelectValue: "anamnesis.HEMATOLOGY.haveCurrentChangeInBloodCountData.values",
    }),
    ...switchWithAutoCompleteFieldConfigConstructor({
        category: Category.HEMATOLOGY,
        pathToSelectValues: "anamnesis.HEMATOLOGY.haveAbdomenUltrasoundData.values",
        pathToSwitchQuestion: "anamnesis.HEMATOLOGY.haveAbdomenUltrasound",
        autocompleteLabel: "anamnesis.hematology.spleen-size-reported",
        fieldLabel: "anamnesis.hematology.spleenExamination",
    }),
    ...switchWithSelectFieldConstructor({
        category: Category.CARDIO_RISK_FACTORS,
        pathToSwitchQuestion: "anamnesis.CARDIOVASCULAR.hasSufferingFromHypercholesterolemia",
        pathToSelectValue: "anamnesis.CARDIOVASCULAR.hypercholesterolemiaState",
        selectOptions: dropDownValues.treatmentStatus,
        label: "anamnesis.cardiovascular.hypercholesterolemia",
    }),
    ...switchWithYearSelectConstructor({
        category: Category.CARDIOVASCULAR_DISEASES,
        label: "anamnesis.cardio.haveMyocardialBiopsy",
        pathToSwitchQuestion: "anamnesis.CARDIO_DISEASE.haveMyocardialBiopsy",
        pathToYearValue: "anamnesis.CARDIO_DISEASE.haveMyocardialBiopsyData.values.date",
    }),
    ...dropdownFieldConfigConstructor({
        category: Category.QUALITY_OF_LIFE,
        pathToValue: "anamnesis.QUALITY_OF_LIFE.anxiousValue",
        dropdownValues: dropDownValues.mentalProblemsSeverity,
        label: "anamnesis.quality.anxiousOrDepressive",
    }),
    ...dropdownFieldConfigConstructor({
        category: Category.QUALITY_OF_LIFE,
        pathToValue: "anamnesis.QUALITY_OF_LIFE.discomfortValue",
        dropdownValues: dropDownValues.discomfortLevel,
        label: "anamnesis.quality.painOrDiscomfort",
    }),
    ...dropdownFieldConfigConstructor({
        category: Category.QUALITY_OF_LIFE,
        pathToValue: "anamnesis.QUALITY_OF_LIFE.mobileValue",
        dropdownValues: dropDownValues.problemSeverity,
        label: "anamnesis.quality.mobilityStatus",
    }),
    ...dropdownFieldConfigConstructor({
        category: Category.QUALITY_OF_LIFE,
        pathToValue: "anamnesis.QUALITY_OF_LIFE.washAndDressValue",
        dropdownValues: dropDownValues.problemSeverity,
        label: "anamnesis.quality.selfCareAbility",
    }),
    ...dropdownFieldConfigConstructor({
        category: Category.QUALITY_OF_LIFE,
        pathToValue: "anamnesis.QUALITY_OF_LIFE.dailyActivityValue",
        dropdownValues: dropDownValues.problemSeverity,
        label: "anamnesis.quality.dailyActivityValueShort",
    }),
    ...switchFieldConstructor({
        category: Category.CARDIO_RISK_FACTORS,
        pathToValue: "anamnesis.CARDIOVASCULAR.sportStatus",
        label: "anamnesis.cardiovascular.sportStatus",
    }),
    ...switchFieldConstructor({
        category: Category.CARDIOVASCULAR_DISEASES,
        pathToValue: "anamnesis.CARDIO_DISEASE.haveStroke",
        label: "anamnesis.cardio.haveStroke",
    }),
    ...switchFieldConstructor({
        category: Category.CARDIOVASCULAR_DISEASES,
        pathToValue: "anamnesis.CARDIO_DISEASE.hasEnlargementOfTheAorta",
        label: "anamnesis.cardio.hasEnlargementOfTheAorta",
    }),
    ...switchWithAdditionalSwitchQuestionAndSelectRendererField({
        pathToSwitchQuestion: "anamnesis.CARDIO_DISEASE.haveCardiacArrhythmia",
        pathToSelectValue: "anamnesis.CARDIO_DISEASE.haveCardiacArrhythmiaType",
        selectOptions: [
            {
                value: "ATRIAL_FIBRILLATION",
                label: "anamnesis.cardio.haveCardiacArrhythmiaType.ATRIAL_FIBRILLATION",
            },
            {
                value: "ATRIAL_FLUTTER",
                label: "anamnesis.cardio.haveCardiacArrhythmiaType.ATRIAL_FLUTTER",
            },
            {
                value: "VENTRICULAR_EXTRASYSTOLE",
                label: "anamnesis.cardio.haveCardiacArrhythmiaType.VENTRICULAR_EXTRASYSTOLE",
            },
            {
                value: "VENTRICULAR_TACHYCARDIA",
                label: "anamnesis.cardio.haveCardiacArrhythmiaType.VENTRICULAR_TACHYCARDIA",
            },
            {
                value: "AVNRT",
                label: "anamnesis.cardio.haveCardiacArrhythmiaType.AVNRT",
            },
            {
                value: "OTHER",
                label: "anamnesis.cardio.haveCardiacArrhythmiaType.OTHER",
            },
            {
                value: "UNKNOWN",
                label: "anamnesis.cardio.haveCardiacArrhythmiaType.UNKNOWN",
            },
        ],
        pathToSecondSwitchQuestion: "anamnesis.CARDIO_DISEASE.haveResuscitated",
        category: Category.CARDIOVASCULAR_DISEASES,
        label: "anamnesis.cardio.haveCardiacArrhythmia",
        secondSwitchLabel: "anamnesis.cardio.haveResuscitated"
    }),
    ...switchWithYearSelectConstructor({
        category: Category.CARDIOVASCULAR_DISEASES,
        pathToYearValue: "anamnesis.CARDIO_DISEASE.haveMiocardialHeartAttackData.values.date",
        pathToSwitchQuestion: "anamnesis.CARDIO_DISEASE.haveMiocardialHeartAttack",
        withEqualYear: true,
        pathToNumberOfEntries: "anamnesis.CARDIO_DISEASE.haveMiocardialHeartAttackData.values_count",
        label: "anamnesis.cardio.haveMiocardialHeartAttack",
    }),
    ...switchWithSelectFieldConstructor({
        pathToSwitchQuestion: "anamnesis.CARDIO_DISEASE.haveAtherosclerosis",
        pathToSelectValue: "anamnesis.CARDIO_DISEASE.haveAtherosclerosisValue",
        category: Category.CARDIOVASCULAR_DISEASES,
        label: "anamnesis.cardio.haveAtherosclerosis",
        selectOptions: dropDownValues.atherosclerosisTreatment,
    }),
    ...switchFieldConstructor({
        category: Category.CARDIOVASCULAR_DISEASES,
        pathToValue: "anamnesis.CARDIO_DISEASE.haveAtherosclerosisLegArteries",
        label: "anamnesis.cardio.haveAtherosclerosisLegArteries",
    }),
    ...switchWithSelectFieldConstructor({
        category: Category.CARDIOVASCULAR_DISEASES,
        pathToSwitchQuestion: "anamnesis.CARDIO_DISEASE.haveAtherosclerosisLegArteries",
        pathToSelectValue: "anamnesis.CARDIO_DISEASE.haveAtherosclerosisLegArteriesStage",
        label: "anamnesis.cardio.haveAtherosclerosisLegArteries",
        selectOptions: dropDownValues.atherosclerosisLegArteriesStage,
    }),
    ...switchWithSelectFieldConstructor({
        category: Category.CARDIOVASCULAR_DISEASES,
        pathToSwitchQuestion: "anamnesis.CARDIO_DISEASE.coronaryArteryDisease",
        pathToSelectValue: "anamnesis.CARDIO_DISEASE.coronaryArteryDiseaseState",
        label: "anamnesis.cardio.coronary-artery-disease",
        selectOptions: [
            { value: "1_VESSEL_AFFECTED", label: "anamnesis.cardio.1_VESSEL_AFFECTED" },
            { value: "2_VESSELS_AFFECTED", label: "anamnesis.cardio.2_VESSELS_AFFECTED" },
            { value: "3_VESSELS_AFFECTED", label: "anamnesis.cardio.3_VESSELS_AFFECTED" },
        ],
    }),
    ...switchWithYearSelectConstructor({
        category: Category.CARDIOVASCULAR_DISEASES,
        label: "anamnesis.cardio.havePlumonaryArteryEmbolism",
        pathToSwitchQuestion: "anamnesis.CARDIO_DISEASE.havePlumonaryArteryEmbolism",
        pathToYearValue: "anamnesis.CARDIO_DISEASE.havePlumonaryArteryEmbolismData.values.date",
        withEqualYear: true,
    }),
    ...switchWithSelectFieldConstructor({
        pathToSwitchQuestion: "anamnesis.CARDIO_DISEASE.haveCardiomypathy",
        pathToSelectValue: "anamnesis.CARDIO_DISEASE.haveCardiomypathyDiagnoseValue",
        label: "anamnesis.cardio.haveCardiomypathy",
        selectOptions: dropDownValues.cardiomypathyDiagnose,
        category: Category.CARDIOVASCULAR_DISEASES,
    }),
    ...percentRangeFieldConstructor({
        label: "anamnesis.quality.scale",
        pathToValue: "anamnesis.QUALITY_OF_LIFE.scaleRateValue",
        category: Category.QUALITY_OF_LIFE,
    }),
    ...nestedFieldConstructor({
        nestedPath: "medications",
        category: Category.MEDICATIONS,
        label: "medication.ingredient",
        allowAndCondition: true,
        allowOrCondition: true,
        showLabel: false,
        andConditionLabel: "cohort.add-to-group.medication",
        canExclude: true,
        virtualFieldPaths: ["medCriteria"],
        nestedConfigs: [
            autocompleteFieldConstructor({
                pathToValue: "medications.ingredient",
                label: "medication.table.ingredient",
                nestedComponent: true,
            }),
            dropdownFieldConfigConstructor({
                pathToValue: "medCriteria",
                dropdownValues: [
                    { value: "ACTIVE", label: "cohort.actual-subscription" },
                    { value: "NON_ACTIVE", label: "cohort.past-subscription" },
                    { value: "NEVER_SUBSCRIBED", label: "cohort.never-subscribed" },
                ],
                label: "cohort.criteria",
                fieldWidth: 208,
                nestedComponent: true,
            }),
        ],
    }),
    ...[
        {
            key: "hemoglobin",
            units: [
                { value: "gperdl", label: "g/dl" },
                { value: "mmolperl", label: "mmol/l" },
            ],
            defaultUnit: "gperdl",
            fieldInStandardUnit: "hemoglobin_in_mmolperl",
        },
        {
            key: "kreatinin",
            units: [
                { value: "mgperdl", label: "mg/dl" },
                { value: "mikromolperll", label: "μmol/l" },
                { value: "nmolperlml", label: "nmol/ml" },
            ],
            defaultUnit: "mgperdl",
            fieldInStandardUnit: "kreatinin_in_mikromolperl",
        },
        { key: "leukozyten", units: [{ value: "gperl", label: "G/l" }], defaultUnit: "gperl" },
        {
            key: "totalcholesterol",
            units: [
                { value: "mgperdl", label: "mg/dl" },
                { value: "mmolperldl", label: "mmol/dl" },
            ],
            defaultUnit: "mgperdl",
            fieldInStandardUnit: "totalcholesterol_in_mmolperl",
        },
        {
            key: "ldlcholesterol",
            units: [
                { value: "mgperdl", label: "mg/dl" },
                { value: "mmolperldl", label: "mmol/dl" },
            ],
            defaultUnit: "mgperdl",
            fieldInStandardUnit: "ldlcholesterol_in_mmolperl",
        },
        {
            key: "hdlcholesterol",
            units: [
                { value: "mgperdl", label: "mg/dl" },
                { value: "mmolperldl", label: "mmol/dl" },
            ],
            defaultUnit: "mgperdl",
            fieldInStandardUnit: "hdlcholesterol_in_mmolperl",
        },
        {
            key: "crp",
            units: [
                {
                    value: "mgperdl",
                    label: "mg/dl",
                },
                {
                    value: "mgperl",
                    label: "mg/l",
                },
                {
                    value: "nmolperll",
                    label: "nmol/l",
                },
                {
                    value: "nmolperml",
                    label: "nmol/ml",
                },
            ],
            defaultUnit: "mgperl",
            fieldInStandardUnit: "crp_in_mgperl",
        },
        { key: "mcv", units: [{ value: "fperl", label: "fl" }], defaultUnit: "fperl" },
        { key: "rdw", units: [{ value: "percent", label: "%" }], defaultUnit: "percent" },
        { key: "thrombozyten", units: [{ value: "gperl", label: "G/l" }], defaultUnit: "gperl" },
        { key: "gfr", units: [{ value: "mlpermin", label: "ml/min" }], defaultUnit: "mlpermin" },
    ].reduce((acc, conf) => {
        return {
            ...acc,
            ...createLabValueConfig(conf),
        };
    }, {}),
};
